@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;600;700&family=Roboto+Slab:wght@700&display=swap");

:root {
    --background: #f5f5f5;
    --primary: #bc3a20;
    --text: #2f4858;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: "Noto Sans";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--background);
    color: var(--text);
    font-size: 1em;
    display: flex;
    justify-content: center;
}

h1 {
    color: var(--primary);
    font-weight: 700;
    font-family: "Roboto Slab";
}

.wrapper {
    padding: 1em;
    background-color: var(--background);
}

.container {
    width: max-content;
    padding: 1em;
}

.grid {
    display: grid;
    grid-template-columns: repeat(3, auto);
    min-width: max-content;
    margin-bottom: auto;
    margin: 0.5em;
}

.instruction {
    display: block;
    text-align: left;
    color: var(--text);
}

.instruction:first-letter {
    text-transform: capitalize;
}

.field {
    display: flex;
    flex-direction: column;
    color: var(--text);
}

label {
    padding-left: 0.5em;
    font-size: 0.75rem;
}

input {
    padding: 0.5em;
    font-size: 1em;
    border-width: 0 0 1px 0.1px;
    border-color: var(--text);
    color: var(--text);
    border-style: inset;
    appearance: none;
    -moz-appearance: none;
    outline: none;
    font-weight: 700;
    font-family: "Noto Sans";
    background-color: transparent;
}

input:active,
input:focus {
    color: var(--primary);
    transition: color 0.1s ease-in-out;
}

button {
    outline: none;
    height: min-content;
    text-align: center;
    background-color: var(--primary);
    border: 0;
    border-radius: 0.5em;
    padding: 0.75em;
    color: #ffffff;
    cursor: pointer;
    margin: 1em;
    font-size: 1rem;
    font-family: "Noto Sans";
    font-weight: 700;
    overflow: hidden;
}

button:hover,
button:focus,
button:active {
    background-color: #ffffff;
    color: var(--primary);
    box-shadow: inset 0 0 0 2px var(--primary);
    transition: all 0.1s ease-in-out;
}

.initial-clear-button {
    display: block;
    visibility: hidden;
}

.contact-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 2em;
    padding-bottom: 2em;
}

a {
    color: var(--primary);
}
a:hover {
    color: var(--text);
}

@media screen and (max-width: 900px) {
    .grid {
        place-items: center;
        grid-template-areas: "food-item food-item food-item" "cooking-time clear-button .";
    }

    .grid > .item {
        align-self: center;
        width: 90%;
        margin: 0.5em 0;
    }

    .grid > .field {
        width: 100%;
        margin: 0.5em 0;
    }

    .grid > .food-item {
        grid-area: food-item;
    }

    .grid > .cooking-time {
        grid-area: cooking-time;
        width: 50%;
        place-self: start;
    }

    .grid > .clear-button {
        grid-area: clear-button;
        width: 100%;
    }

    .flex > .item {
        align-self: center;
        margin: 0.25em 0;
    }

    .container {
        width: auto;
    }

    .initial-clear-button {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .container {
        border: 0;
        margin: 0;
        border-radius: 0;
        border-bottom: 1px solid var(--primary);
    }

    .flex > .item {
        margin: 0.25em 0;
    }
}
